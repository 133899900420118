.deckItemContainer {
  background-color: $gray-light;
  margin: 10px 0;

  .deckItem {
    padding: 10px;

    .deleteIconContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}
