@import '../_variables.scss';
@import '../_base.scss';

.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .dropzoneLabel {
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1rem;
    font-family: 'Helvetica-Roman', sans-serif;
    font-weight: 400;
    line-height: 1;
    transform: translate(0, 1.5px) scale(0.75);
    margin-bottom: .7rem;
  }
  .dropzoneSection {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $gray;
    border: 1px dashed $gray-nano;
    border-radius: 0.375rem;
    transition: all 0.15s ease;
    padding: 2rem 1rem;
    &:hover {
      cursor: pointer;
      border: 1px dashed $gray;
      color: $gray-dark;
    }
  }
  .uploadedImage {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row-reverse;
    position: relative;
    height: 100%;
    margin-bottom: 11px;
    .deleteImage {
      display: flex;
      margin-left: 0.5rem;
      &:hover {
        cursor: pointer;
      }
    }
    .image {
      display: block;
      width: 300px;
      height: 300px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

.formButton {
  margin-top: 16px !important;
  &.addUser {
    background-color: $info;
    color: $white;
    &:hover {
      background-color: $info-hover;
    }
  }
}

.createData {
  background-color: $success !important;
  color: $white !important;
  &:hover {
    background-color: $success-hover !important;
  }
}

.errorMessage {
  color: $danger;
}

.textInput {
  div:nth-child(1) {
    width: 100%;
  }
}

.logoLight {
  background-color: $gray;
}