.snackbar {
  > div {
    flex-wrap: nowrap;
  }
  .snackbarMassage {
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
      margin-bottom: 1px;
    }
  }
  &.success {
    > div {
      background-color: $success;
    }
  }
  &.danger {
    > div {
      background-color: $danger;
    }
  }
  svg {
    font-size: 20px;
  }
}
