.sensor-positioning {
  position: relative;
  max-width: 100%;
  overflow-x: scroll;
}

.sensor-positioning-grid {
  position: absolute;
  top: 0;
  left: 0;
}

.sensor-positioning-grid-row {

}

.sensor-positioning-grid-col {

}

.sensor-positioning-grid-unit {
  box-sizing: border-box;
  border: 1px solid black;
  &:hover {
    background-color: rgba(255, 255, 0, 0.3);
  }
}

.sensor-positioning-grid-unit-selected {
  background-color: rgba(0, 255, 0, 0.5);
  &:hover {
    background-color: rgba(0, 255, 0, 0.9);
  }
}

.sensor-positioning {
  > img {
    background: rgb(0,75,131);
  }

  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }
  .loader {
    color: #000000;
    font-size: 10px;
    margin: 80px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 3.5em;
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
}