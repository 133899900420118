@import '../_variables.scss';
@import '../_base.scss';

html {
  height: 100%;
}

body {
  margin: 0;
}

p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.7;
}

a {
  color: $black;
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Helvetica-Bold';
  font-weight: 600;
  color: $primary-dark;
}

h1 {
  font-size: 1.625rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1.0625rem;
}

h4 {
  font-size: 0.9375rem;
}

h5 {
  font-size: 0.8125rem;
}

h6 {
  font-size: 0.625rem;
}

button {
  transition: all 0.15s ease !important;
  &:hover {
    transform: translateY(-1px);
  }
  &.Mui-disabled {
     opacity: .6;
  }
}

label,
.label {
  color: $gray-dark;
  padding: 0;
  font-size: 0.75rem;
  font-family: 'Helvetica-Bold', sans-serif;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 0.4rem;
}

.buttonSuccess {
  background-color: $success !important;
  color: $white !important;
  box-shadow: 0px 1px 5px 0px rgba(76, 175, 80, 0.28),
    0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 3px 1px -2px rgba(76, 175, 80, 0.2) !important;
  &:hover {
    background-color: $success-hover !important;
    transform: translateY(-1px);
  }
}

.buttonInfo {
  background-color: $info !important;
  color: $white !important;
  box-shadow: 0 1px 5px 0px rgba(0, 188, 212, 0.14),
    0 2px 2px 0px rgba(0, 188, 212, 0.2), 0 3px 1px -2px rgba(0, 188, 212, 0.12) !important;
  &:hover {
    background-color: $info-hover !important;
    transform: translateY(-1px);
  }
}

.buttonDanger {
  background-color: $danger !important;
  color: $white !important;
  box-shadow: 0 1px 5px 0px rgba(0, 188, 212, 0.14),
    0 2px 2px 0px rgba(0, 188, 212, 0.2), 0 3px 1px -2px rgba(0, 188, 212, 0.12) !important;
  &:hover {
    background-color: $danger-hover !important;
    transform: translateY(-1px);
  }
}

.buttonPrimary {
  background-color: $primary !important;
  color: $white !important;
  box-shadow: 0 1px 5px 0px rgba(0, 188, 212, 0.14),
    0 2px 2px 0px rgba(0, 188, 212, 0.2), 0 3px 1px -2px rgba(0, 188, 212, 0.12) !important;
  &:hover {
    background-color: $primary-dark !important;
    transform: translateY(-1px);
  }
}

.buttonSecondary {
  background-color: $secondary !important;
  color: $white !important;
  box-shadow: 0 1px 5px 0px rgba(0, 188, 212, 0.14),
    0 2px 2px 0px rgba(0, 188, 212, 0.2), 0 3px 1px -2px rgba(0, 188, 212, 0.12) !important;
  &:hover {
    background-color: $secondary-dark !important;
    transform: translateY(-1px);
  }
}

.badge-dot {
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  margin-right: 0.5rem;
  &.bg-success {
    background-color: $success;
  }
}

.primary {
  color: $primary !important;
}

.primary-light {
  color: $primary-light !important;
}

.primary-dark {
  color: $primary-dark !important;
}

.secondary {
  color: $secondary !important;
}

.secondary-light {
  color: $secondary-light !important;
}

.secondary-dark {
  color: $secondary-dark !important;
}

.gray {
  color: $gray !important;
}

.gray-light {
  color: $gray-light !important;
}

.gray-dark {
  color: $gray-dark !important;
}

.black {
  color: $black !important;
}

.white {
  color: $white !important;
}

.success {
  color: $success !important;
}

.info {
  color: $info !important;
}

.danger {
  color: $danger !important;
}

.message-error {
  color: $danger;
  margin: 0 0 5px;
  background: initial !important;
}

.contentCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  padding-bottom: 0.2rem;
  margin-left: -0.2rem;
  background: $primary;
  color: $white;
  width: 36px;
  height: 36px;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
}

.MuiGrid-container {
  .MuiGrid-item {
    margin-bottom: 1rem;
  }
}

.MuiCard-root {
  .MuiCardHeader-root {
    border-bottom: 1px solid $gray-nano;
    .MuiCardHeader-title {
      font-size: 1.0625rem;
      font-weight: 600;
      margin-bottom: 0.1rem;
    }
    .MuiCardHeader-subheader {
      font-size: 0.875rem;
    }
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid  $gray-nano;
}

.draggable-container {
  position: relative;
}

.draggable-sensor {
  width: 20px;
  cursor: pointer;
}

.hidden-tab {
  visibility: hidden;
  height: 0;
}

.hidden-hotspot {
  display: none;
}

.MuiTable-root {
  min-width: 650px;
}