@import '_variables.scss';
@import '_base.scss';

body {
  padding: 0 !important;
}

.MuiCard-root {
  overflow: visible !important;
}

.autorization {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100vh + 11rem);
  background-color: $navy-blue;
  .infoSection {
    position: relative;
    width: 100%;
    padding: 4rem 0;
    .textContent {
      margin-bottom: 2rem;
      h1,
      h3 {
        color: $white;
        text-align: center;
      }
    }

    .logo {
      background-image: none !important;
      margin: 0 auto 16px auto;
      width: 56px;
      height: 65px;
      @media (min-width: 576px) {
        width: 96px;
        height: 100px;
      }
      @media (min-width: 1200px) {
        position: absolute;
        left: 16px;
        top: 16px;
      }
    }

    p {
      color: $gray-light;
      text-align: center;
    }
  }
  .separator {
    position: absolute;
    top: auto;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    width: 100%;
    height: 150px;
    transform: translateZ(0);
    pointer-events: none;
    svg {
      position: absolute;
      bottom: 0;
      .fill-default {
        fill: $primary-dark;
      }
    }
  }
  .formSection {
    position: relative;
    .autorizationCard {
      position: absolute;
      top: -4rem;
      .headerTitle {
        padding-bottom: 0;
        span {
          font-family: 'Helvetica-Bold';
          font-size: 1.0625rem;
          color: grey;
          text-align: center;
        }
      }
      .recaptchaVerifier {
        > div {
          display: flex;
          justify-content: center;
          margin: 2rem 0;
        }
      }
      .text-field-container {
        color: $danger;
      }
      .buttonContainer {
        display: flex;
        justify-content: center;
        button {
          background: $primary;
          color: $white;
          &:hover {
            background: $primary-dark;
            transition: 0.5s;
          }
        }
      }
      .verifyCode {
        p {
          color: $text;
        }
        .codeInput {
          margin-bottom: 1rem;
          .errorMessage {
            color: $danger;
            margin-top: 0.8rem;
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}

.customChip {
  background-color: lightgray;
  border-radius: 10px;
  padding: 4px 8px;
  svg {
    margin-right: 8px;
  }
}

.buttonContainerChip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .customChip {
    margin-top: 8px;
  }
  @media (min-width: 768px) {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .customChip {
      margin-top: 0;
    }
  }
}

.navigationDrawer {
  .sideBar {
    .companyName {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      min-width: 220px;
      h4 {
        margin-left: 20px;
      }
      .companyName-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
    }
    .menuContainer {
      margin: auto;
    }
    .listContainer {
      min-width: 240px;
      span {
        font-size: 0.875rem;
        color: $text;
      }
      .iconContainer {
        @media (min-width: 600px) {
          margin-left: 6px;
        }
      }
    }
  }
  .currentCompany {
    &:hover {
      cursor: pointer;
    }
  }
  main {
    min-height: 100vh;
    background: rgba(0, 75, 131, 1);
  //  margin-left: 73px;
  //  @media (max-width: 600px) {
  //    margin-left: 52px;
  //  }
  }
}

.avatarContainer {
  padding: 0 !important;
  .avatarContainer-header {
    display: block;
    width: 100%;
    background: $primary;
    height: 150px;
  }
  .avatarContainer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    padding-top: 80px;
    position: relative;
    .profileImage {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10rem;
      background: $gray-nano;
      background-image: none;
      width: 180px;
      height: 180px;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      position: absolute;
      top: -90px;
      border: 3px solid #fff;
      transition: all 0.15s ease;
      &:hover {
        transform: scale(1.03);
      }
      .dropzone {
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        padding: 0 1rem 0;
        height: 100%;
        .dropzoneSection {
          padding: 0;
          height: 100%;
          border: none;
          text-align: center;
        }
        .uploadedImage {
          .deleteImage {
            position: absolute;
            left: 150px;
          }
          .image {
            width: 173px;
            height: 173px;
            border-radius: 50%;
          }
        }
      }
    }
    .userName {
      font-family: 'Helvetica-Roman';
      color: $text;
      margin-bottom: 0.1rem;
    }
    .userRole {
      text-transform: lowercase;
      font-family: 'Helvetica-Bold';
      color: $text;
    }
  }
}

.customTable {
  thead {
    tr {
      color: #8898aa;
      background-color: #f6f9fc;
      th {
        color: #8898aa;
        font-size: 0.65rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        letter-spacing: 1px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      th {
        color: $text;
        border-bottom: none;
        &:first-child {
          display: flex;
          align-items: center;
          .avatar {
            margin-right: 1rem;
          }
        }
      }
      td {
        color: $text;
        border-bottom: none;
        .currentCompany {
          display: flex;
          flex-direction: revert;
          align-items: center;
          justify-content: flex-end;
          font-size: 0.875rem;
          font-weight: 400;
          text-transform: none;
          color: $text;
          &:hover {
            cursor: default;
          }
        }
      }
    }
  }
  .loadMore {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    border-top: 1px solid rgba(224, 224, 224, 1);
    button {
      border-color: $primary;
      color: $primary;
      &:hover {
        background-color: $primary;
        color: $white;
      }
    }
  }
}

.profileForm {
  h4 {
    font-family: 'Helvetica-Roman';
    font-size: 0.75rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #8898aa !important;
  }
}

.addUserForm {
  padding: 1rem;
  .selectInput {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.companyForm {
  h4 {
    font-family: 'Helvetica-Roman';
    font-size: 0.75rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #8898aa !important;
  }
}

.updateCompany {
  padding: 1rem;
}

.companiesList {
  padding: 1rem;
  .topContainer{
    justify-content: flex-end;
    @media (max-width: 1279px) {
      justify-content: flex-start;
    }
    
  }
}

.createCompany {
  padding: 1rem;
}

.ships {
  padding: 1rem;
}

.sensors {
  padding: 1rem;
}

.sensor-status {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: auto;
  &.offline {
    //background-color: $danger-hover;
    background: rgb(255,255,255);
    background: linear-gradient(225deg, rgba(255,255,255,1) 14%, rgba(236,63,97,1) 50%);
  }
  &.online {
    background: rgb(255,255,255);
    background: linear-gradient(225deg, rgba(255,255,255,1) 14%, rgba(76,175,80,1) 50%);
  }
}

.profile {
  padding: 1rem;
}

.decks {
  .decksSection {
    .decksSection-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .decksSection-body {
      margin-top: 1rem;
      .deckItemContainer {
        background-color: transparent;
      }
      .wrapper {
        .topWrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0;
          padding: 1.25rem 1.5rem;
        }
        .bodyWrap {
          padding: 1.5rem;
        }
      }
    }
  }
}

.createShip {
  padding: 1rem;
  .decks {
    .cardContent {
      background: linear-gradient(87deg, $primary 0, $primary-light 100%);
      .decksSection {
        .decksSection-header {
          h2,
          .MuiFormControlLabel-label {
            color: $white;
          }
        }
        hr {
          border-color: $white;
        }
      }
    }
  }
  .usersSection {
    .noUsers {
      color: $gray;
      margin-top: 0.5rem;
    }
  }
  .MuiCard-root {
    overflow: inherit;
  }
  .MuiCardContent-root {
    border-radius: 4px;
  }
}

.usersSection {
  .cardContent {
    background: linear-gradient(87deg, $info 0, #1171ef 100%);
    .requiredSelect {
      position: relative;
      background-color: $danger;
      padding: 2px;
      border-radius: 5px;
      &:after {
        content: 'Required';
        position: absolute;
        font-size: 100%;
        color: $danger-hover;
        bottom: -20px;
        left: 4px;
      }
    }
    .usersSection-header {
      margin-bottom: 1.8rem;
      h2,
      span {
        color: $white;
      }
    }
    hr {
      border-color: $white;
    }
    .noUsers {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $danger;
      margin: 2rem 0;
      font-size: 20px;
      font-weight: 600;
      text-shadow: $gray-nano -1px -1px 3px;
    }
    label {
      color: $white;
    }
    .MuiInput-underline {
      &:before {
        border-bottom: 1px solid $white;
      }
      input {
        color: $white;
      }
    }
    .userRole {
      display: flex;
      p {
        color: $text;
      }
    }
  }
  .selectInput {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .buttonInfo {
    margin-top: 1.05rem;
  }
  .singleUserCard {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .deleteButton-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.usersList {
  padding: 1rem;
  .usersListContainer {
    position: relative;
    .usersListSearch {
      position: absolute;
      @media (min-width: 650px) {
        top: 20px;
        right: 30px;
      }
    }
  }
}

@media (max-width: 650px) {
  .usersList {
      .usersListContainer {
          .usersListHeader {
            padding-bottom: 75px;
          }
          .usersListSearch {
            top: 75px;
            left: 30px;
            @media (max-width: 425px) {
              top: 95px;
            }
          }
      }
  }
}

.sensor {
  overflow: inherit !important;
}

.addSensor {
  padding: 1rem;
  .sensorInformation {
    overflow: inherit;
    margin-bottom: 2rem;
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    margin-bottom: 20px;
  }
  .modalBack {
    padding: 20px 15px;
    border-radius: 5px;
    background-color: $white;
    box-shadow: 2px 2px 10px 2px $black;
    .buttonsWrap {
      display: flex;
      justify-content: center;
      .buttonSuccess {
        margin-right: 25px;
      }
    }
  }
}
