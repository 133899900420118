@keyframes ldio {
  0% {
    transform: translate(6px, 40px) scale(0);
  }
  25% {
    transform: translate(6px, 40px) scale(0);
  }
  50% {
    transform: translate(6px, 40px) scale(1);
  }
  75% {
    transform: translate(40px, 40px) scale(1);
  }
  100% {
    transform: translate(74px, 40px) scale(1);
  }
}
@keyframes ldio-r {
  0% {
    transform: translate(74px, 40px) scale(1);
  }
  100% {
    transform: translate(74px, 40px) scale(0);
  }
}
@keyframes ldio-c {
  0% {
    background: #f83fb4;
  }
  25% {
    background: #f83fb4;
  }
  50% {
    background: #ff9202;
  }
  75% {
    background: #000000;
  }
  100% {
    background: #f83fb4;
  }
}
.ldio div {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translate(40px, 40px) scale(1);
  background: #f83fb4;
  animation: ldio 1.9607843137254901s infinite cubic-bezier(0, 0.5, 0.5, 1);
}
.ldio div:nth-child(1) {
  background: #000000;
  transform: translate(74px, 40px) scale(1);
  animation: ldio-r 0.49019607843137253s infinite cubic-bezier(0, 0.5, 0.5, 1),
    ldio-c 1.9607843137254901s infinite step-start;
}
.ldio div:nth-child(2) {
  animation-delay: -0.49019607843137253s;
  background: #f83fb4;
}
.ldio div:nth-child(3) {
  animation-delay: -0.9803921568627451s;
  background: #000000;
}
.ldio div:nth-child(4) {
  animation-delay: -1.4705882352941175s;
  background: #ff9202;
}
.ldio div:nth-child(5) {
  animation-delay: -1.9607843137254901s;
  background: #f83fb4;
}

.htmlSpinner {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 2000;
  background-image: url('/images/bg-pattern.png');
}

.logo {
  display: flex;
  width: 400px;
  height: 121px;
  background-image: url('/images/logo-tototheo-maritime.jpg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 3001;
}

.loadingio-spinner-ellipsis {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  background: none;
  margin-top: -30px;
}
.ldio {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.ldio div {
  box-sizing: content-box;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
