.dashboard {
  .topWrapper {
    height: 15rem;
    background: $primary;
    padding: 1rem;
    @media (max-width: 1280px) {
      height: auto;
    }
    .infoItem {
      .row {
        &:first-child {
          margin-bottom: 1rem;
        }
      }
      h3 {
        color: $gray-dark;
        text-transform: uppercase;
      }
      span {
        text-transform: uppercase;
        font-family: "Helvetica-Bold";
        font-size: 1.6rem;
      }
      .iconWrap {
        display: flex;
        justify-content: flex-end;
        height: 100%;
        @media (max-width: 1280px) {
          justify-content: flex-end;
        }
        .circle {
          border-radius: 50%;
          padding: 1rem;
          &.iconShip {
            background: linear-gradient(87deg, #f5365c, #f56036) !important;
            .icon {
              background-image: url("/images/ship.svg");
            }
          }
          &.iconUser {
            background: linear-gradient(87deg, #fb6340, #fbb140) !important;
            .icon {
              background-image: url("/images/users.svg");
            }
          }
          &.iconSensor {
            background: linear-gradient(87deg, #2dce89, #2dcecc) !important;
            .icon {
              background-image: url("/images/sensor.svg");
            }
          }
          &.iconAlert {
            background: linear-gradient(87deg, #5e72e4, #825ee4) !important;
            .icon {
              background-image: url("/images/alert.svg");
            }
          }
          .icon {
            display: flex;
            width: 30px;
            height: 30px;
          }
        }
      }
      .secondary-row {
        display: flex;
        align-items: center;
        color: $danger;
        font-size: 1.2rem;
      }
      .difference {
        display: flex;
        align-items: center;
        color: $success;
        font-size: 1.2rem;
        .direction {
          display: flex;
          margin-right: 3px;
          svg {
            font-size: 1.2rem;
          }
        }
      }
      p {
        color: $text;
        margin-left: 10px;
      }
    }
  }
  .content {
    padding: 1rem;
    margin-top: -2rem;
    .firstChart {
      background-color: #172b4d !important;
      padding: 1.5rem;
      border-radius: 4px;
      width: 100%;
      height: 300px;
      padding-bottom: 3rem;
      h3 {
        color: $white;
        margin-bottom: 1rem;
      }
    }
    .secondChart {
      background-color: $white;
      padding: 1.5rem;
      border-radius: 4px;
      width: 100%;
      height: 300px;
      padding-bottom: 3rem;
      h3 {
        color: $text;
        margin-bottom: 1rem;
      }
    }
  }
}
