@font-face {
  font-family: 'Helvetica-Light';
  src: url('/fonts/HelveticaNeue/HelveticaNeueLTStd-Light.ttf');
}

@font-face {
  font-family: 'Helvetica-Roman';
  src: url('/fonts/HelveticaNeue/HelveticaNeueLTStd-Roman.ttf');
}

@font-face {
  font-family: 'Helvetica-Bold';
  src: url('/fonts/HelveticaNeue/HelveticaNeueLTStd-Bold.ttf');
}

